<template>
    <div>
      <el-row :gutter="20" class="search-box">
        
        <el-col :span="6">
          <el-date-picker
            v-model="dateRange"
            type="datetimerange"
            @change="changeDate"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-col>
  
        <el-col :span="4">
          <el-select placeholder="类型" v-model="query.detailType" clearable>
            <el-option
              v-for="(item, index) in this.const.USER_ASSETS_TYPE"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-col>
  
        <el-col :span="4">
          <el-button type="primary" @click="getList(1)">查询</el-button>
  
          <el-button type="primary" @click="doExport">用户资产导出</el-button>
        </el-col>
      </el-row>
  
      <el-table
        border
        :data="dataSource"
        class="page-top-space"
        row-class-name="table-row"
      >
        <el-table-column label="姓名" prop="realName"></el-table-column>
  
        <el-table-column label="变更前" align="right" prop="beforeScore"></el-table-column>
        
        <el-table-column label="积分" align="right" prop="score"></el-table-column>
  
        <el-table-column label="操作后金额" align="right" prop="afterScore"></el-table-column>
  
        <el-table-column label="变更类型" prop="detailType">
          <template #default="scope">
            <eb-badge
              :list="this.const.SOURCE_TYPE"
              :target="scope.row.sourceType"
            ></eb-badge>
          </template>
        </el-table-column>
  
  
        <el-table-column label="备注" prop="remark"></el-table-column>
  
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
      </el-table>
  
      <el-pagination
        layout="total, prev, pager, next"
        background
        :total="total"
        :page-size="pages.pageSize"
        :current-page="pages.pageIndex"
        @current-change="getList"
      ></el-pagination>
  
    </div>
  </template>
  
  
  <script>
  import {
    fetchScorePage
  } from "@/api/user";
  import moment from "moment";
  
  export default {
   
    data() {
      let startTime = moment()
        .startOf("day")
        .subtract(1, "month").format("x");
      let endTime = moment().endOf("day").format("x");
      return {
        dateRange: [],
       
        dataSource: [],
        modalData: {},
        pages: {
          pageIndex: 1,
          pageSize: 10,
        },
        query: {
          endTime: endTime,
          startTime: startTime,
        },
        total: 0,
      };
    },
  
    mounted() {
      this.getList(1);
    },
  
    methods: {
      changeDate(e) {
        if (!this.validatenull(e)) {
          this.query.startTime = moment(e[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss");
          this.query.endTime =  moment(e[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss");
          this.dateRange = e;
        } else {
          this.query.startTime = "";
          this.query.endTime = "";
        }
        this.getList(1);
      },
   
  
      getList(current) {
        if (!this.validatenull(current)) {
          this.pages.pageIndex = current;
        }
        fetchScorePage({ ...this.query, ...this.pages }).then((res) => {
          let list = res.data.data.records || [];
          this.total = res.data.data.total;
          this.dataSource = list;
        });
      },
    },
  };
  </script>
  